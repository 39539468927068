<template>
  <v-text-field
    class="sx-currency-input"
    :value="formattedValue"
    v-currency="options"
    :disabled="disabled"
    @change="onChange"
    @input="onInput"
    :rules="rules"
    :label="label"
    ref="input"
    :clearable="clearable"
    @click:clear="clear($event)"
    outlined
    dense
  />
</template>

<script>
export default {
  name: "sx-currency-input",
  props: {
    value: {
      type: Number,
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
    clear() {
      this.$emit("click:clear", null);
    },
    onInput(value) {
      if (!this.readonly)
        this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      if (!this.readonly)
        this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
  },
};
</script>
