var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    directives: [
      {
        name: "currency",
        rawName: "v-currency",
        value: _vm.options,
        expression: "options",
      },
    ],
    ref: "input",
    staticClass: "sx-currency-input",
    attrs: {
      value: _vm.formattedValue,
      disabled: _vm.disabled,
      rules: _vm.rules,
      label: _vm.label,
      clearable: _vm.clearable,
      outlined: "",
      dense: "",
    },
    on: {
      change: _vm.onChange,
      input: _vm.onInput,
      "click:clear": function ($event) {
        return _vm.clear($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }